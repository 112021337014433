<template>
  <div class="container">

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-4">

      <!-- section students -->
      <section v-if="adviserStudents.length">
        <h4 class="text-center mb-4">
          Студенты
        </h4>

        <DataTable :value="adviserStudents" :paginator="true" :rows="20"
                   showGridlines
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                   :rowsPerPageOptions="[20,30,40,50,100,200,500]"
                   :globalFilterFields="['barcode', 'last_name','first_name','middle_name']"
                   v-model:filters="filters"
                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" stripedRows
                   responsiveLayout="scroll">
          <template #header>
            <div class="p-d-flex p-jc-between p-ai-center">
              <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Поиск студента"/>
              </span>
            </div>
          </template>
          <Column header="№" :style="{width: '60px'}">
            <template #body="{index}">
              {{index+1}}
            </template>
          </Column>
          <Column header="Баркод" field="barcode"></Column>
          <Column header="ФИО">
            <template #body="{data}">
              {{data?.last_name}} {{data?.first_name}}
            </template>
          </Column>
          <Column header="Перейти">
            <template #body="{data}">
              <ul>
                <li>
                  <router-link :to="`/schedule/student?student_id=${data.student_id}`" target="_blank"
                               class="text-decoration-none">
                    Расписание
                  </router-link>
                </li>
                <li>
                  <router-link :to="`/student/choose-disciplines?barcode=${data.barcode}`" target="_blank"
                               class="text-decoration-none">
                    ИУП
                  </router-link>
                </li>
              </ul>



            </template>
          </Column>
        </DataTable>

      </section>
      <!-- end section students -->


      <!-- section select students -->
      <section class="my-5">
        <Button :label="selectStudentsVisible?'Скрыть выбор студентов':'Показать выбор студентов'"
                class="p-button-link mt-5" @click="changeSelectStudentVisible"/>

        <div v-if="selectStudentsVisible">
          <h4 class="text-center my-4">
            Выбор студентов
          </h4>

          <div>

            <!-- filter -->
            <section>
              <div class="row mb-4">
                <div class="col-md-3">
                  Уровень обучения
                </div>
                <div class="col-md-9">
                  <Dropdown v-model="studyLevelId" :options="adviserChoose_form.studyLevels" class="w100"
                            optionLabel="name" optionValue="id" placeholder="Выберите уровень обучения"/>
                </div>
              </div>

              <div class="row mb-4">
                <div class="col-md-3">
                  Язык обучения
                </div>
                <div class="col-md-9">
                  <Dropdown v-model="studyLanguageId" :options="studyLanguages" class="w100"
                            optionLabel="native_name" optionValue="id" placeholder="Выберите язык обучения"/>
                </div>
              </div>


              <div class="row mb-4" v-if="studyLevelId&&studyLanguageId">
                <label class="col-md-3">Образовательная программа</label>
                <div class="col-md-9">
                  <MultiSelect v-model="educationProgramIds" :options="educationProgramsWithIdAndName" class="w100"
                               optionLabel="name" optionValue="id" placeholder="Выберите образовательную программу"/>

                </div>
              </div>

              <div v-if="educationProgramIds.length" class="my-4 text-center">
                <Button icon="pi pi-eye" label="Показать" class="p-button-rounded"
                        :loading="loadingStudents" @click="getStudentsByEducationPrograms"/>
              </div>
            </section>
            <!-- end filter -->

            <section v-if="students.length" class="my-5">
              <DataTable :value="students" showGridlines stripedRows responsiveLayout="scroll">
                <Column header="№" :style="{width: '60px'}">
                  <template #body="{index}">
                    {{index+1}}
                  </template>
                </Column>
                <Column header="Баркод" field="barcode"></Column>
                <Column header="ФИО">
                  <template #body="{data}">
                    {{data?.last_name}} {{data?.first_name}}
                  </template>
                </Column>
                <Column>
                  <template #header>
                    Выбрать все <input class="ms-2 form-check-input" type="checkbox"
                                       :checked="checkedStudents.length===students.length"
                                       @input="checkAllStudents">
                  </template>
                  <template #body="{data}">
                    <input class="form-check-input" type="checkbox" :value="data.id" v-model="checkedStudents">
                  </template>
                </Column>
              </DataTable>

              <!--          <div v-if="checkedStudents.length" class="text-center my-4">-->
              <!--            <div class="my-3 fs-5">-->
              <!--              Для сохранения выберите минимум 25 студентов. Сейчас выбрано {{checkedStudents.length}}-->
              <!--            </div>-->

              <!--            <Button v-if="checkedStudents.length>24" icon="pi pi-save" label="Сохранить" class="p-button-rounded"-->
              <!--                    :loading="loadingSaveStudents" @click="saveStudents"/>-->
              <!--          </div>-->

              <div v-if="checkedStudents.length" class="text-center my-4">
                <Button icon="pi pi-save" label="Сохранить" class="p-button-rounded"
                        :loading="loadingSaveStudents" @click="saveStudents"/>
              </div>
            </section>

          </div>
        </div>


      </section>
      <!-- end section select student -->


    </div>
  </div>
</template>


<script>

  import {mapActions, mapState} from "vuex"
  import {FilterMatchMode, FilterOperator} from "primevue/api";


  export default {
    name: "AdviserChooseStudents",
    data() {
      return {
        loading: true,

        studyLevelId: 0,
        studyLanguageId: 0,
        educationProgramIds: [],

        loadingStudents: false,
        students: [],
        checkedStudents: [],

        loadingSaveStudents: false,

        adviserStudents: [],

        selectStudentsVisible: false,
        filters: {
          'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
          'barcode': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
          },
          'last_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
          },
          'first_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
          },
          'middle_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
          },
        },
      }
    },
    computed: {
      ...mapState('adviserChoose', ['adviserChoose_form']),
      ...mapState('departmentPractice', ['studyLanguages', 'educationPrograms']),
      educationProgramsWithIdAndName() {
        return this.educationPrograms.map(i => ({
          id: i.id,
          name: `${i.education_speciality_code} ${i.education_speciality_name} - ${i.admission_year}`
        }))
      }
    },
    watch: {
      studyLevelId() {
        this.clearAllData()
      },
      studyLanguageId() {
        this.clearAllData()
      },
    },
    methods: {
      ...mapActions('adviserChoose', ['GET_STUDY_LEVELS']),
      ...mapActions('departmentPractice', ['GET_STUDY_LANGUAGES', 'GET_EDUCATION_PROGRAMS']),
      ...mapActions('adviser', ['GET_STUDENTS_BY_EDUCATION_PROGRAMS', 'POST_ADVISER_STUDENT', 'GET_ADVISER_STUDENT']),
      async clearAllData() {
        this.educationProgramIds = []
        this.clearStudents()

        if (this.studyLevelId && this.studyLanguageId) {
          await this.GET_EDUCATION_PROGRAMS({studyLevel: this.studyLevelId, studyLanguage: this.studyLanguageId})
        }
      },
      async getStudentsByEducationPrograms() {
        this.loadingStudents = true

        this.checkedStudents = []
        this.students = await this.GET_STUDENTS_BY_EDUCATION_PROGRAMS(this.educationProgramIds)

        this.loadingStudents = false
      },
      clearStudents() {
        this.students = []
      },
      checkAllStudents(e) {
        this.checkedStudents = e.target.checked ? this.students.map(i => i.id) : []
      },
      async saveStudents() {
        this.loadingSaveStudents = true

        const form = this.checkedStudents.map(student_id => ({student_id}))
        const res = await this.POST_ADVISER_STUDENT(form)

        if (res) {
          this.checkedStudents = []
          this.students = []
          this.adviserStudents = await this.GET_ADVISER_STUDENT()
          this.$message({title: 'Успешно сохранено'})
        }

        this.loadingSaveStudents = false
      },
      changeSelectStudentVisible() {
        this.selectStudentsVisible = !this.selectStudentsVisible
      }
    },
    async mounted() {
      this.adviserStudents = await this.GET_ADVISER_STUDENT()

      if (!this.adviserStudents.length) {
        this.selectStudentsVisible = true
      }

      await this.GET_STUDY_LEVELS()
      await this.GET_STUDY_LANGUAGES()

      this.loading = false
    },
  }
</script>

<style scoped>
  .w100 {
    width: 100%;
  }
</style>
